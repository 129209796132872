<template>
  <section class="main">
    <!-- RAG -->
    <div class="container">
      <div class="left">
        <!-- <el-button class="btn" @click="backLast()" size="small">返回</el-button> -->
        <!-- 分类 -->
        <!-- <div class="class">
            <div class="classFont">RAG</div>
            <div class="classActive">数据库</div>
           </div> -->
        <div class="box" @click="newCreated()">
          <!-- <div>
              <img size="small" src="../../assets/home/small-logo.png" alt="" />
              <span>平行方舟大模型</span>
            </div>
            <div class="new" @click="newCreated()">+新建</div> -->
          <img src="https://oss.zijingshuke.com/1719910718961.png" alt="" />
          <span>新建对话</span>
        </div>
        <!-- 历史记录 -->
        <div class="hisarr">
          <!-- <div @click="addhistory(item)" class="text" v-for="(item,i) in historyArr" :key="i">{{ item.info }}</div> -->
        </div>
      </div>
      <div class="right">
        <!-- 对话框 -->

        <div v-show="isShow" class="content" ref="chatContainer">
          <div class="chat-messages">
            <div
              ref="dialog"
              class="chat"
              v-for="(message, index) in messages"
              :key="index"
              style="white-space: pre-wrap"
            >
              <div
                v-show="message.isUser"
                class="message"
                :class="{ 'user-message': message.isUser }"
              >
                <div>
                  <img
                    style="width: 35px"
                    src="../../assets/home/avatar.png"
                    alt=""
                  />
                </div>
                <div class="input1box">
                  <span class="highlight" @click="copyText(message.text)">
                    {{ message.text }}</span
                  >
                </div>
              </div>

              <div v-show="!message.isUser" class="kefu">
                <div class="replay">
                  <img
                    v-show="!message.loading"
                    class="kefuAvatar"
                    src="../../assets/home/small-logo.png"
                    alt=""
                  />
                  <i
                    v-show="message.loading"
                    style="font-size: 2rem; color: blue; margin-left: 25px"
                    class="el-icon-loading"
                  ></i>
                  <div
                    class="replay-box"
                    style="width: 90%"
                    v-show="!message.loading"
                  >
                    <div>
                      <!-- echarts图 -->
                      <div v-show="message.isChart && !message.loading">
                        <div
                          :id="'chart-' + index"
                          style="width: 500px; height: 300px"
                        ></div>
                      </div>
                      <!-- table图 -->
                      <div v-show="message.isTable && !message.loading">
                        <el-table :data="message.rows">
                          <el-table-column
                            v-for="(header, index) in message.headers"
                            :key="index"
                            :prop="header"
                            :label="header"
                          ></el-table-column>
                        </el-table>
                      </div>
                    </div>
                    <!-- 回答异常 -->
                     <div v-show="message.isReplay && !message.loading">
                      <p>{{ message.replay }}</p>
                     </div>
                    <div class="bottom">
                      <img
                        v-show="!message.isGood && !message.loading"
                        @click="isGoodClick(index)"
                        src="https://oss.zijingshuke.com/1719989713217.png"
                        alt=""
                      />
                      <img
                        v-show="message.isGood && !message.loading"
                        @click="isGoodClick(index)"
                        src="https://oss.zijingshuke.com/1719990847913.png"
                        alt=""
                      />
                      <img
                        v-show="message.isBad && !message.loading"
                        @click="isBadClick(index)"
                        src="https://oss.zijingshuke.com/1719990746538.png"
                        alt=""
                      />
                      <img
                        v-show="!message.isBad && !message.loading"
                        @click="isBadClick(index)"
                        src="https://oss.zijingshuke.com/1719990761916.png"
                        alt=""
                      />
                      <img
                        v-show="index < messages.length - 1 && !message.loading"
                        @click="copyText(message.replay)"
                        src="https://oss.zijingshuke.com/1719990826543.png"
                        alt=""
                      />
                      <img
                        v-show="
                          index == messages.length - 1 && !message.loading
                        "
                        @click="copyText(message.replay)"
                        src="https://oss.zijingshuke.com/1719990826543.png"
                        alt=""
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- 提示 -->
        <div v-show="!isShow" class="tips">
          <div class="image">
            <img src="../../assets/home/small-logo.png" alt="" />
          </div>
          <div class="tip">
            <p class="span" @mouseup="handleMouseSelect">
              你好，我是平行方舟数据库大模型
            </p>
            <p class="p">
              欢迎向我提问,可以帮你高效完成AI问答，提高你的工作学习效率。
            </p>
          </div>
        </div>
        <div class="askInput">
          <textarea
            class="textarea"
            v-model="newMessage"
            @keyup.enter="sendMessage"
            placeholder="很高兴为您服务，请描述您的问题"
          />
          <img
            class="send"
            @click="sendMessage"
            src="../../assets/drive/send.png"
            alt=""
          />
        </div>
      </div>
    </div>
    <!-- 添加文献图标 -->
    <!-- <div class="addfile">
        <el-tooltip
          class="item"
          effect="dark"
          content="添加文献"
          placement="top-start"
        >
          <img src="https://oss.zijingshuke.com/1720572848959.png" alt="" />
        </el-tooltip>
      </div> -->
    <!-- 添加文献模块 -->
    <div class="addfile-box" v-show="isAddFile">
      <div class="addfile-section">
        <p class="font">添加文献</p>
      </div>
    </div>
  </section>
</template>

<script>
import * as echarts from "echarts";
// 在页面加载完成后执行
window.onload = function () {
  // 将滚动条置于顶部
  setTimeout(function () {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, 100);
};
export default {
  data() {
    return {
      chartData: {
        chart_type: "table",
        headers: ["数量", "UID", "num"],
        rows: [
          { count: 5, uid: 2, num: 12 },
          { 数量: 1, uid: 1, num: 10 },
        ],
      },
      headers: ["数量", "UID", "num"],
      rows: [
        { count: 5, uid: 2, num: 12 },
        { 数量: 1, uid: 1, num: 10 },
      ],
      lineData: {
        type: "line",
        x: [
          "\u4e00\u6c27\u5316\u78b3",
          "\u6c34\u6d78",
          "\u4f20\u611f\u56681",
          "\u4f20\u611f\u56682",
          "\u4f20\u611f\u56683",
          "\u6c34\u88681",
        ],
        y: [2, 2, 2, 2, 1, 1],
      },
      barData: {
        type: "bar",
        x: [
          "\u4e00\u6c27\u5316\u78b3",
          "\u6c34\u6d78",
          "\u4f20\u611f\u56681",
          "\u4f20\u611f\u56682",
          "\u4f20\u611f\u56683",
          "\u6c34\u88681",
        ],
        y: [2, 2, 2, 2, 1, 1],
      },
      pieData: {
        type: "pie",
        x: [
          "\u4e00\u6c27\u5316\u78b3",
          "\u6c34\u6d78",
          "\u4f20\u611f\u56681",
          "\u4f20\u611f\u56682",
          "\u4f20\u611f\u56683",
          "\u6c34\u88681",
        ],
        y: [2, 2, 2, 2, 1, 1],
      },
      // 是否显示添加文献模块
      isAddFile: false,
      // 是否显示对话框
      isShow: false,
      // 当前时间
      time: "",
      //  问答数组
      messages: [],
      // 问题
      newMessage: "",
      //回复
      replay: "",
      // 历史记录
      historyArr: [
        { info: "RAG是什么", replay: "gggg" },
        { info: "一年有几个月", replay: "12个" },
      ],
      // 回复蹦出来的数组
      concatenatedReplay: [],
      // 流式数据
      flowData: "",
      x: [],
      y: [],
      type: "",
    };
  },
  mounted() {},
  methods: {
    // 使回复一个一个蹦出来
    concatenateReplay() {
      this.messages.forEach((message, index) => {
        let concatenatedString = "";
        let strIndex = 0;
        const interval = 10; // 每个字符之间的间隔时间，单位为毫秒

        if (message.replay) {
          const timer = setInterval(() => {
            if (strIndex < message.replay.length) {
              concatenatedString += message.replay[strIndex];
              this.$set(this.concatenatedReplay, index, concatenatedString);
              strIndex++;
            } else {
              clearInterval(timer); // 清除定时器
              console.log("xia" + strIndex);
            }
          }, interval);
        }
      });
    },
    // 复制文本
    copyText(text) {
      const textarea = document.createElement("textarea");
      textarea.value = text;
      document.body.appendChild(textarea);
      textarea.select();
      document.execCommand("copy");
      document.body.removeChild(textarea);
      this.$message.success("复制成功");
    },
    // 返回上一页
    backLast() {
      this.$router.push("/map");
    },
    // 发送问题
    sendMessage() {
      this.isShow = true;
      console.log(this.isShow);
      if (this.newMessage.trim() !== "") {
        this.messages.push({
          text: this.newMessage,
          isUser: true,
          time: this.time,
          loading: true,
          isChart: false,
        });
        this.$nextTick(() => {
          this.scrollToBottom();
        });
        this.request();
        this.newMessage = "";
      }
    },

    // 请求问题答案
    request() {
      var that = this;
      var param = {
        query: this.newMessage,
      };
      this.messages.push({
        replay: "正在加载中~",
        isUser: false,
        time: this.time,
        loading: true,
        isGood: false,
        isBad: false,
        isChart: false,
        isTable: false,
      });
      this.chartData = {};
      this.$post("/ask", param)
        .then((res) => {
          // 主要参数
          console.log(res);
          this.messages.pop();
           this.$nextTick(() => {
            this.scrollToBottom();
          });

          if (res.chart.chart_type == "table") {
            const mappedRows = res.chart.rows.map((row) => {
              const mappedRow = {};
              Object.keys(row).forEach((key, index) => {
                mappedRow[res.chart.headers[index]] = row[key];
              });
              return mappedRow;
            });

            setTimeout(() => {
              this.messages.push({
                replay: this.replay,
                isUser: false,
                time: this.time,
                loading: false,
                isGood: false,
                isBad: false,
                id: this.messages.length,
                isChart: false,
                isTable: true,
                dataChart: res.chart,
                rows: mappedRows,
                headers: res.chart.headers,
              });
            }, 100);
            console.log(this.messages);
          } else if (
            res.chart.chart_type == "line" ||
            res.chart.chart_type == "bar"
          ) {
            that.lineData = res.chart.data;
            console.log(that.lineData);
            setTimeout(() => {
              const lineDatas = {
                tooltip: {
                  trigger: "axis",
                },
                xAxis: {
                  type: "category",
                  data: that.lineData.x,
                  axisLabel: {
                    interval: 0, // 或者不设置 interval 属性
                  },
                },
                yAxis: {
                  type: "value",
                },
                series: [
                  {
                    data: that.lineData.y,
                    type: res.chart.chart_type,
                    itemStyle: {
                      // 设置图的颜色
                      color: "#59bdf8",
                    },
                  },
                ],
              };
              setTimeout(() => {
                this.messages.push({
                  replay: "11",
                  isUser: false,
                  time: this.time,
                  loading: false,
                  isGood: false,
                  isBad: false,
                  id: this.messages.length,
                  isChart: true,
                  chartData: lineDatas,
                  isTable: false,
                });
                this.$nextTick(() => {
                  this.messages.forEach((message, index) => {
                    if (message.isChart && !message.isUser) {
                      console.log(index);
                      const chartContainer = document.getElementById(
                        "chart-" + index
                      );
                      console.log(chartContainer);
                      const chart = echarts.init(chartContainer);
                      chart.setOption(message.chartData);
                    }
                  });
                });
              }, 10);
            }, 100);
          } else if (res.chart.chart_type == "pie") {
            that.lineData = res.chart.data;
            console.log(res.chart.data);
            // 将 x 和 y 数组转换为指定格式的对象数组
            const transformedData = res.chart.data.x.map((item, index) => {
              return {
                name: item,
                value:res.chart.data.y[index],
              };
            });
            console.log(transformedData)
            setTimeout(() => {
              const lineDatas = {
                tooltip: {
                  trigger: "item",
                },
                legend: {
                  orient: "vertical",
                  left: "left",
                },
                series: [
                  {
                    name: "平行方舟",
                    type: "pie",
                    radius: "50%",
                    data: transformedData,
                    emphasis: {
                      itemStyle: {
                        shadowBlur: 10,
                        shadowOffsetX: 0,
                        shadowColor: "rgba(0, 0, 0, 0.5)",
                      },
                    },
                  },
                ],
              };
              setTimeout(() => {
                this.messages.push({
                  replay: "11",
                  isUser: false,
                  time: this.time,
                  loading: false,
                  isGood: false,
                  isBad: false,
                  id: this.messages.length,
                  isChart: true,
                  chartData: lineDatas,
                  isTable: false,
                });
                this.$nextTick(() => {
                  this.messages.forEach((message, index) => {
                    if (message.isChart && !message.isUser) {
                      console.log(index);
                      const chartContainer = document.getElementById(
                        "chart-" + index
                      );
                      // console.log(chartContainer);
                      const chart = echarts.init(chartContainer);
                      chart.setOption(message.chartData);
                    }
                  });
                });
              }, 10);
            }, 100);
          }

          this.$nextTick(() => {
            this.scrollToBottom();
          });
        })
        .catch((res) => {
          console.log(res);
          console.log("异常");
          // this.$message.error("抱歉，请求异常，您的问题暂时无法回答");
          this.messages.pop();
          this.messages.push({
            replay: "抱歉，您的问题暂时无法回答",
            isUser: false,
            time: this.time,
            loading: false,
            isGood: false,
            isBad: false,
            isChart: false,
            isReplay:true
          });
          this.$nextTick(() => {
            this.scrollToBottom();
          });
        });
    },

    scrollToBottom() {
      // 在初始化时将滚动条滚动到底部
      this.$nextTick(() => {
        let chatContainer = this.$refs.chatContainer;
        chatContainer.scrollTop = chatContainer.scrollHeight;
      });
    },
    // 添加历史记录
    addhistory(item) {
      this.messages.push({
        text: item.info,
        isUser: true,
        time: this.time,
        loading: true,
        isGood: false,
        isBad: false,
      });
      this.$nextTick(() => {
        this.scrollToBottom();
      });
      setTimeout(() => {
        this.messages.push({
          replay: item.replay,
          isUser: false,
          time: this.time,
          loading: false,
        });
        this.$nextTick(() => {
          this.scrollToBottom();
        });
      }, 100);
    },
    // 点赞
    isGoodClick(i) {
      this.messages[i].isGood = !this.messages[i].isGood;
      this.messages[i].isBad = false;
    },
    // 踩
    isBadClick(i) {
      this.messages[i].isBad = !this.messages[i].isBad;
      this.messages[i].isGood = false;
    },
    // 新建
    newCreated() {
      this.isShow = false;
      this.messages = [];
    },
    handleMouseSelect() {
      let text = window.getSelection().toString();
      console.log(text);
    },
    // 返回按钮
    ToMap() {
      this.$router.push("/map");
    },
  },
  beforeDestroy() {
    // 清除定时器
    if (this.setTime) {
      clearInterval(this.setTime);
    }
  },
};
</script>

<style lang="scss" scoped>
.input1box {
  width: 90%;
  margin: 0 10px;
}
/* 隐藏 Element UI 文本域清除按钮图标 */
::v-deep .el-input__suffix .el-input__clear {
  display: none;
}
::v-deep .el-textarea {
  .el-textarea__inner {
    resize: none; // 去除右下角图标
  }
}

.input1 {
  display: flex;
  align-items: center;
  box-sizing: border-box;

  .el-textarea__inner {
    background: #ccc !important;
    color: #000;
    font-size: 18px;
    border-radius: 10px;
    padding-top: 18px;
    box-sizing: border-box;
  }
}

.input2 {
  .el-textarea__inner {
    border: none;
    outline: none;
  }
}
</style>
<style scoped lang="scss">
.main {
  position: relative;
}
// table表格

// 分类
.class {
  display: flex;
  justify-content: space-around;
  margin: 10px auto;
  cursor: pointer;

  .classActive {
    color: #0e1ce9;
    border-bottom: 2px solid #6d5ffd;
    line-height: 28px;
  }

  .classFont {
    color: #000;
    line-height: 28px;
  }
}

// 添加文献图标
.addfile {
  position: absolute;
  right: 20px;
  top: 20px;

  img {
    width: 35px;
  }
}

// 添加文献模块
.addfile-box {
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);

  .addfile-section {
    width: 60%;
    height: 500px;
    background: #fff;
    margin: 80px auto 0;
    border-radius: 20px;
    padding: 10px;
    box-sizing: border-box;

    .font {
      width: 100%;
      text-align: center;
    }
  }
}

.container {
  display: flex;
  overflow-x: hidden;

  .left {
    background: #e9e9f4;
    width: 22%;
    height: calc(100vh - 115px);
     height: 100vh;
    .btn {
      margin: 20px 20px 0;
    }

    .box {
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;
      margin: 60px 20px 20px 20px;
      background: linear-gradient(to right, #dd88ff, #6d5ffd);
      padding: 10px 5px;
      box-sizing: border-box;
      // border: 2px solid transparent;
      border-radius: 25px;

      // overflow: hidden;
      img {
        vertical-align: middle;
        width: 25px;
        margin-right: 10px;
      }

      span {
        vertical-align: middle;
        color: #fff;
        cursor: pointer;
        font-size: 19px;
      }

      .new {
        color: #4955f5;
        font-size: 20px;
        cursor: pointer;
      }
    }

    // 历史记录
    .hisarr {
      .text {
        margin: 20px 25px;
        cursor: pointer;
        user-select: text;
      }
    }
  }

  .right {
    background: #ebeef7;
    width: 80%;
    height: calc(100vh - 95px);
    height: 100vh;
    position: relative;

    .tips {
      width: 80%;
      margin: 20px auto;
      height: 400px;
      background: #e9e9f4;
      padding: 20px;
      border-radius: 20px;
      box-shadow: inset 0 0 0 2px rgb(201, 137, 187);

      .image {
        width: 100%;
        text-align: center;
        margin-top: 100px;
      }

      .span {
        width: 100%;
        text-align: center;
        margin: 10px auto;
        font-size: 18px;
        font-weight: 600;
        user-select: auto;
        z-index: 99;
      }

      .p {
        width: 100%;
        text-align: center;
        margin: 10px auto;
        user-select: auto;
      }
    }

    .content {
      margin: 20px 0 20px 20px;
      // width: 100%;
      height: 68vh;
      overflow-y: scroll;
    }

    .chat-messages {
      height: 100%;
      width: 90%;
      margin: 0 5%;

      .message {
        border-radius: 5px;
        box-sizing: border-box;
        white-space: pre-wrap;
        display: flex;

        .highlightInput {
          border: none;
          outline: none;
          margin: 0 10px;
          padding: 5px;
          background: #ccc;
          font-size: 16px;
          resize: none;
          /* 禁止用户手动调整大小 */
          // overflow: hidden; /* 隐藏溢出的内容 */
          width: 100%;
          /* 设置初始宽度 */
          height: auto;
          border: none;
          /* 去除边框 */
        }

        span {
          vertical-align: middle;
          margin: 0 10px;
          user-select: text;
        }

        img {
          vertical-align: middle;
          width: 35px;
        }
      }

      .askTime {
        color: #333333;
      }

      .user-message {
        color: #fff;
        white-space: wrap;
        margin: 10px 0;

        span {
          background-color: #ccc;
          color: #000;
          padding: 5px;
          border-radius: 5px;
          user-select: text;
          box-sizing: border-box;
          display: inline-block;
          vertical-align: middle;
        }
      }

      .agent-message {
        background-color: #eee;
        float: left;
        clear: both;
      }

      // 客服回复
      .kefu {
        color: #333333;

        img {
          vertical-align: middle;
          width: 38px;
          height: 38px;
          border-radius: 50%;
        }

        span {
          vertical-align: middle;
          user-select: text;
        }

        .replay {
          margin: 20px 0;
          display: flex;

          .replay-box {
            background: #fff;
            padding: 10px 15px 10px 10px;
            margin: 0 10px;
            border-radius: 10px;
            box-sizing: border-box;

            .bottom {
              width: 100%;
              text-align: right;

              img {
                width: 25px;
                height: 25px;
              }
            }
          }

          span {
            border-radius: 3px;

            // margin: 10px 0;
            display: inline-block;
            user-select: text;
          }
        }
      }
    }

    .askInput {
      width: 80%;
      min-height: 120px;
      border: 2px solid rgb(137, 157, 201);
      // box-shadow:  inset 0 0 0 2px rgb(201, 137, 187);
      border-radius: 5px;
      position: absolute;
      bottom: 30px;
      left: 10%;
      background: #fff;

      .textarea {
        width: 100%;
        min-height: 80px;
        font-size: 16px;
        padding: 5px;
        box-sizing: border-box;
      }

      textarea {
        border: none;
        resize: none;
      }

      textarea:focus,
      textarea:active {
        border: none;
        outline: none;
      }

      textarea::-webkit-input-placeholder {
        color: #ccc;
        font-size: 16px;
        padding: 5px;
        box-sizing: border-box;
      }

      .send {
        position: absolute;
        width: 30px;
        right: 3px;
        bottom: 2px;
      }
    }
  }
}
</style>